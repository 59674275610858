import {EFilterByListMessages} from "desiren-core-lib/lib/types/messages/filter-by-list.messages.enum";
import {UUID} from "crypto";


export interface AllGetChatListParams {
  type: EFilterByListMessages.ALL;
}

export interface UnreadGetChatListParams {
  type: EFilterByListMessages.UNREAD;
}

export interface ListGetChatListParams {
  type: EFilterByListMessages.LIST;
  listId: UUID;
}

export type FilterGetChatListParams = AllGetChatListParams | UnreadGetChatListParams | ListGetChatListParams;

export class GetChatListParams {
    public page = 1;
    public limit = 16;
    public filterBy: FilterGetChatListParams = {
      type: EFilterByListMessages.ALL
    } satisfies AllGetChatListParams;

    constructor(data: {
      page: number,
      limit: number,
      filter: FilterGetChatListParams;
    }) {
      Object.assign(this, data);
    }
}

export class GetMessageListParams {
    chatId = '';
    page = 1;
    limit = 50;

    constructor(chatId?: string, page?: number) {
        this.chatId = chatId || '';
        this.page = page || 1;
    }
}

export class GetDelayedMessageListParams {
    chatId = '';
    page = 1;
    limit = 50;

    constructor(chatId?: string, page?: number) {
        this.chatId = chatId || '';
        this.page = page || 1;
    }
}
